<template>
  <div
    class="dark:divide-neutral-dark-1 divide-neutral-light-1 space-y-4 divide-y"
  >
    <template v-if="renderSkeletons">
      <MovieListItemSkeleton v-for="(_, key) in skeletons" :key />
    </template>
    <template v-else>
      <MovieListItem
        v-for="movie in movies"
        :key="movie.id"
        :movie
        :hide-date
        :hide-link
        class="pb-6 pt-6 first-of-type:pt-0 last-of-type:pb-0"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Movie, MovieInterface, LinkParam } from '#gql/default'

interface Props {
  renderAboveTheFold?: boolean
  hideDate?: boolean
  hideLink?: boolean
  linkParams?: LinkParam[]
  renderSkeletons?: number
  movies?: MovieInterface[] | Movie[]
}

const { renderSkeletons } = defineProps<Props>()

const skeletons = computed(() => Array.from({ length: renderSkeletons ?? 0 }))

defineOptions({
  name: 'MovieList',
})
</script>
